.profile-picture-wrapper {
  align-items: center;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;

  > * {
    max-height: 100%;
    max-width: 100%;
    min-height: unset !important;
    min-width: unset !important;
  }
}
